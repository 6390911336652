<template>
  <Toast />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">REPORTES</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active"
        >INFORME MENSUAL Y GESTIÓN</span
      >
    </li>
  </ul>

  <div
    class="surface-section px-4 py-5 md:px-12 lg:px-12"
    style="border: 1px solid #000000"
  >
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <span style="border-bottom: 1px solid #005f91; font-size: 1.5rem">
        <strong>INFORME POR MES Y GESTIÓN</strong>
      </span>
    </div>

    <div class="col-12">
      <div class="p-fluid formgrid grid p-fondo">
        <div class="field col-12 md:col-3">
          <span><strong>SELECCIONE SUCURSAL: </strong></span>
          <div class="p-inputgroup">
            <Dropdown
              :disabled="true"
              v-model="sucursalSelected"
              :options="sucursales"
              optionLabel="nombre"
              optionValue="id"
              placeholder="Seleccione una sucursal"
            />
          </div>
        </div>

        <div class="field col-12 md:col-3">
          <span><strong>SELECCIONE MES: </strong></span>
          <div class="p-inputgroup">
            <Dropdown
              v-model="mes_seleccionado"
              :options="meses"
              optionLabel="label"
              placeholder="Seleccione un mes"
            />
          </div>
        </div>

        <div class="field col-12 md:col-3">
          <span><strong>SELECCIONE AÑO: </strong></span>
          <div class="p-inputgroup">
            <InputNumber
              v-model="anio_gestion"
              :min="2021"
              :max="new Date().getFullYear()"
              :showButtons="true"
              mode="decimal"
              :useGrouping="false"
            />
          </div>
        </div>

        <div class="field col-12 md:col-3">
          <span><strong>ACCIONES: </strong></span>
          <Button
            label="APLICAR FILTROS"
            icon="pi pi-search"
            class="p-button-info"
            v-tooltip.top="'Aplicar Filtros de Busqueda'"
            :loading="enviando"
            :disabled="enviando"
            @click="BuscarFiltro"
          />
        </div>
      </div>

      <div class="p-fluid formgrid grid mt-2">
        <div class="field col-12">
          <div v-if="enviando" class="text-center">
            <ProgressSpinner />
          </div>
          <div
            v-if="
              semana1.length ||
              semana2.length ||
              semana3.length ||
              semana4.length ||
              semana5.length
            "
          >
            <template
              v-for="(semana, index) in [
                semana1,
                semana2,
                semana3,
                semana4,
                semana5,
              ]"
              :key="index"
            >
              <div v-if="semana.length > 0" class="mb-4">
                <h3 class="estilo_dias">Semana {{ index + 1 }}</h3>
                <div class="table-responsive" style="overflow-x: auto">
                  <table class="table table-striped table-bordered">
                    <thead class="thead-dark">
                      <tr>
                        <th rowspan="2">Fecha</th>
                        <th colspan="3">Productos</th>
                        <th colspan="4">Servicios</th>
                        <th colspan="4">Caja</th>
                        <th rowspan="2">Compras</th>
                        <th rowspan="2">Total</th>
                      </tr>
                      <tr>
                        <th>Cant.</th>
                        <th>Total Bs.</th>
                        <th>Prom. Bs.</th>
                        <th>Cant.</th>
                        <th>Total Bs.</th>
                        <th>Prom. Bs.</th>
                        <th>Membresías(0)</th>
                        <th>Ingresos</th>
                        <th>Egresos</th>
                        <th>Comisiones</th>
                        <th>Costos</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(dato, idx) in semana" :key="idx">
                        <td>{{ formatFecha(dato.fecha) }}</td>
                        <td>{{ sinDecimales(dato.cant_productos) }}</td>
                        <td>
                          {{
                            convertirNumeroGermanicFormat(dato.total_productos)
                          }}
                        </td>
                        <td>
                          {{
                            getPromedioProductos(
                              dato.total_productos,
                              dato.cant_productos
                            )
                          }}
                        </td>
                        <td>{{ sinDecimales(dato.cant_servicios) }}</td>
                        <td>
                          {{
                            convertirNumeroGermanicFormat(dato.total_servicios)
                          }}
                        </td>
                        <td>
                          {{
                            getPromedioServicios(
                              dato.total_servicios,
                              dato.cant_servicios,
                              dato.cant_membresias
                            )
                          }}
                        </td>
                        <td>
                          {{
                            convertirNumeroGermanicFormat(dato.cant_membresias)
                          }}
                        </td>
                        <td>
                          {{
                            convertirNumeroGermanicFormat(
                              dato.total_ingresos_caja
                            )
                          }}
                        </td>
                        <td>
                          {{
                            convertirNumeroGermanicFormat(
                              dato.total_egresos_mov_caja
                            )
                          }}
                        </td>
                        <td>
                          {{
                            convertirNumeroGermanicFormat(dato.total_comisiones)
                          }}
                        </td>
                        <td>
                          {{ convertirNumeroGermanicFormat(dato.total_costos) }}
                        </td>
                        <td>
                          {{
                            convertirNumeroGermanicFormat(dato.total_compras)
                          }}
                        </td>
                        <td>
                          {{ convertirNumeroGermanicFormat(dato.total_dia) }}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td class="text-right"><strong>TOTALES</strong></td>
                        <td>
                          <strong>
                            {{
                              convertirNumeroGermanicFormat(
                                semana.reduce(
                                  (acc, item) => acc + item.cant_productos,
                                  0
                                )
                              )
                            }}
                          </strong>
                        </td>
                        <td>
                          <strong>
                            {{
                              convertirNumeroGermanicFormat(
                                semana.reduce(
                                  (acc, item) => acc + item.total_productos,
                                  0
                                )
                              )
                            }}
                          </strong>
                        </td>
                        <td></td>
                        <td>
                          <strong>
                            {{
                              convertirNumeroGermanicFormat(
                                semana.reduce(
                                  (acc, item) => acc + item.cant_servicios,
                                  0
                                )
                              )
                            }}
                          </strong>
                        </td>
                        <td>
                          <strong>
                            {{
                              convertirNumeroGermanicFormat(
                                semana.reduce(
                                  (acc, item) => acc + item.total_servicios,
                                  0
                                )
                              )
                            }}
                          </strong>
                        </td>
                        <td></td>
                        <td>
                          <strong>
                            {{
                              convertirNumeroGermanicFormat(
                                semana.reduce(
                                  (acc, item) => acc + item.cant_membresias,
                                  0
                                )
                              )
                            }}
                          </strong>
                        </td>
                        <td>
                          <strong>
                            {{
                              convertirNumeroGermanicFormat(
                                semana.reduce(
                                  (acc, item) => acc + item.total_ingresos_caja,
                                  0
                                )
                              )
                            }}
                          </strong>
                        </td>
                        <td>
                          <strong>
                            {{
                              convertirNumeroGermanicFormat(
                                semana.reduce(
                                  (acc, item) =>
                                    acc + item.total_egresos_mov_caja,
                                  0
                                )
                              )
                            }}
                          </strong>
                        </td>
                        <td>
                          <strong>
                            {{
                              convertirNumeroGermanicFormat(
                                semana.reduce(
                                  (acc, item) => acc + item.total_comisiones,
                                  0
                                )
                              )
                            }}
                          </strong>
                        </td>
                        <td>
                          <strong>
                            {{
                              convertirNumeroGermanicFormat(
                                semana.reduce(
                                  (acc, item) => acc + item.total_costos,
                                  0
                                )
                              )
                            }}
                          </strong>
                        </td>
                        <td>
                          <strong>
                            {{
                              convertirNumeroGermanicFormat(
                                semana.reduce(
                                  (acc, item) => acc + item.total_compras,
                                  0
                                )
                              )
                            }}
                          </strong>
                        </td>
                        <td>
                          <strong>
                            {{
                              convertirNumeroGermanicFormat(
                                semana.reduce(
                                  (acc, item) => acc + item.total_dia,
                                  0
                                )
                              )
                            }}
                          </strong>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </template>
          </div>
          <div v-else class="text-center">
            <span
              style="
                color: red;
                font-size: 1.2rem;
                font-weight: bold;
                text-align: center;
              "
              ><i class="pi pi-info-circle"></i> Por favor, seleccione un mes y
              año para mostrar los datos.</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VentaService from "@/service/VentaService";
import SucursalService from "@/service/SucursalService";
import { useAuth } from "@/stores";

export default {
  data() {
    return {
      enviando: false,
      sucursalSelected: 0,
      sucursales: [{ id: 0, nombre: "TODAS" }],
      meses: [
        { label: "ENERO", value: 1 },
        { label: "FEBRERO", value: 2 },
        { label: "MARZO", value: 3 },
        { label: "ABRIL", value: 4 },
        { label: "MAYO", value: 5 },
        { label: "JUNIO", value: 6 },
        { label: "JULIO", value: 7 },
        { label: "AGOSTO", value: 8 },
        { label: "SEPTIEMBRE", value: 9 },
        { label: "OCTUBRE", value: 10 },
        { label: "NOVIEMBRE", value: 11 },
        { label: "DICIEMBRE", value: 12 },
      ],
      mes_seleccionado: null,
      anio_gestion: new Date().getFullYear(),
      semana1: [],
      semana2: [],
      semana3: [],
      semana4: [],
      semana5: [],
    };
  },
  created() {
    this.ventaService = new VentaService();
    this.sucursalService = new SucursalService();
    this.auth = useAuth();
  },
  mounted() {
    this.cargarSucursales();
    this.mes_seleccionado = this.meses.find(
      (mes) => mes.value === new Date().getMonth() + 1
    );
  },
  methods: {
    getPromedioProductos(total, cantidad) {
      return cantidad > 0
        ? this.convertirNumeroGermanicFormat(total / cantidad)
        : 0;
    },

    getPromedioServicios(total, cantidad, membresias) {
      let cantidadTotal = cantidad - membresias;
      return cantidadTotal > 0
        ? this.convertirNumeroGermanicFormat(total / cantidadTotal)
        : 0;
    },
    BuscarFiltro() {
      this.semana1 = [];
      this.semana2 = [];
      this.semana3 = [];
      this.semana4 = [];
      this.semana5 = [];
      this.enviando = true;
      this.cargarReporteMensualGestion();
    },
    cargarReporteMensualGestion() {
      let datos = {
        sucursal_id: this.sucursalSelected,
        anio_gestion: this.anio_gestion,
        mes_seleccionado: this.mes_seleccionado.value,
      };
      this.ventaService
        .filtrarReporteMensualGestion(datos)
        .then((data) => {
          this.semana1 = data.datos.semana1 ?? [];
          this.semana2 = data.datos.semana2 ?? [];
          this.semana3 = data.datos.semana3 ?? [];
          this.semana4 = data.datos.semana4 ?? [];
          this.semana5 = data.datos.semana5 ?? [];
          this.enviando = false;
        })
        .catch((error) => {
          this.enviando = false;
          console.log(error);
        });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(numero);
    },
    sinDecimales(numero) {
      return (Math.round(numero * 10) / 10).toLocaleString("es-ES", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    cargarSucursales() {
      this.sucursalService
        .getSucursalesAll()
        .then((data) => {
          data.forEach((sucursal) => {
            this.sucursales.push(sucursal);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatFecha(fecha) {
      // Crear un nuevo objeto Date a partir de la fecha sin hora
      const [year, month, day] = fecha.split("-");
      const localDate = new Date(year, month - 1, day); // Ajusta el mes, que en JS es 0-indexado

      return new Intl.DateTimeFormat("es-ES", {
        dateStyle: "short",
      }).format(localDate);
    },
  },
};
</script>

<style scoped lang="scss">
.p-fondo {
  background-color: #7bffae;
  border: 1px solid #000000;
  border-bottom: 2px solid #000000;
}
.table-responsive {
  width: 100%;
  margin-top: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      text-align: center;
      vertical-align: middle;
      padding: 0.2rem;
      font-size: 1rem;
      border: 1px solid #ddd;
    }

    th {
      background-color: #002236;
      color: white;
      font-size: 1rem;
      font-weight: bold;
    }

    tbody tr:nth-child(even) {
      background-color: #f9f9f9;
    }

    tbody tr:nth-child(odd) {
      background-color: #ffffff;
    }

    tfoot {
      font-weight: bold;
      background-color: #e9ecef;

      td {
        color: #002236;
      }
    }

    /* Colores por sección */
    td:nth-child(2),
    td:nth-child(3),
    td:nth-child(4) {
      /* Productos */
      background-color: #e6f7ff;
      color: #007bff;
    }

    td:nth-child(5),
    td:nth-child(6),
    td:nth-child(7),
    td:nth-child(8) {
      /* Servicios */
      background-color: #fff3cd;
      color: #856404;
    }

    td:nth-child(9),
    td:nth-child(14) {
      /* Caja */
      background-color: #d4edda;
      color: #155724;
    }
    td:nth-child(10),
    td:nth-child(11),
    td:nth-child(12),
    td:nth-child(13) {
      /* Compras y Total */
      background-color: #f8d7da;
      color: #721c24;
    }
  }
}

/* Estilos para los títulos de las semanas */
.estilo_dias {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #002236;
  text-transform: uppercase;
  text-align: center;
}

/* Estilos responsivos */
@media (max-width: 768px) {
  table {
    font-size: 0.8rem;

    th,
    td {
      padding: 0.5rem;
    }
  }

  .estilo_dias {
    font-size: 1.2rem;
  }
}
</style>
